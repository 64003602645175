import { FieldPositionType, FigureType } from "../types";


export default function Field(props: FieldPositionType[number]) {
	return (
		<div className={` bg-${props.color} w-8 h-8 rounded-full border border-white`} style={{ "gridColumn": props.x, "gridRow": props.y }}>
		</div>
	);
}


export function getFieldLayout(figures: FigureType[]): FieldPositionType {
	const colors = { "blue": 0, "yellow": 1, "green": 2, "red": 3, "none": -1 };

	// Create a mapping of the board positions
	const positionMapping: FieldPositionType = [];
	positionMapping[1] = { x: 5, y: 11, position: 1, color: "none" };
	positionMapping[2] = { x: 5, y: 10, position: 2, color: "none" };
	positionMapping[3] = { x: 5, y: 9, position: 3, color: "none" };
	positionMapping[4] = { x: 5, y: 8, position: 4, color: "none" };
	positionMapping[5] = { x: 5, y: 7, position: 5, color: "none" };
	positionMapping[6] = { x: 4, y: 7, position: 6, color: "none" };
	positionMapping[7] = { x: 3, y: 7, position: 7, color: "none" };
	positionMapping[8] = { x: 2, y: 7, position: 8, color: "none" };
	positionMapping[9] = { x: 1, y: 7, position: 9, color: "none" };
	positionMapping[10] = { x: 1, y: 6, position: 10, color: "none" };
	positionMapping[11] = { x: 1, y: 5, position: 11, color: "none" };
	positionMapping[12] = { x: 2, y: 5, position: 12, color: "none" };
	positionMapping[13] = { x: 3, y: 5, position: 13, color: "none" };
	positionMapping[14] = { x: 4, y: 5, position: 14, color: "none" };
	positionMapping[15] = { x: 5, y: 5, position: 15, color: "none" };
	positionMapping[16] = { x: 5, y: 4, position: 16, color: "none" };
	positionMapping[17] = { x: 5, y: 3, position: 17, color: "none" };
	positionMapping[18] = { x: 5, y: 2, position: 18, color: "none" };
	positionMapping[19] = { x: 5, y: 1, position: 19, color: "none" };
	positionMapping[20] = { x: 6, y: 1, position: 20, color: "none" };
	positionMapping[21] = { x: 7, y: 1, position: 21, color: "none" };
	positionMapping[22] = { x: 7, y: 2, position: 22, color: "none" };
	positionMapping[23] = { x: 7, y: 3, position: 23, color: "none" };
	positionMapping[24] = { x: 7, y: 4, position: 24, color: "none" };
	positionMapping[25] = { x: 7, y: 5, position: 25, color: "none" };
	positionMapping[26] = { x: 8, y: 5, position: 26, color: "none" };
	positionMapping[27] = { x: 9, y: 5, position: 27, color: "none" };
	positionMapping[28] = { x: 10, y: 5, position: 28, color: "none" };
	positionMapping[29] = { x: 11, y: 5, position: 29, color: "none" };
	positionMapping[30] = { x: 11, y: 6, position: 30, color: "none" };
	positionMapping[31] = { x: 11, y: 7, position: 31, color: "none" };
	positionMapping[32] = { x: 10, y: 7, position: 32, color: "none" };
	positionMapping[33] = { x: 9, y: 7, position: 33, color: "none" };
	positionMapping[34] = { x: 8, y: 7, position: 34, color: "none" };
	positionMapping[35] = { x: 7, y: 7, position: 35, color: "none" };
	positionMapping[36] = { x: 7, y: 8, position: 36, color: "none" };
	positionMapping[37] = { x: 7, y: 9, position: 37, color: "none" };
	positionMapping[38] = { x: 7, y: 10, position: 38, color: "none" };
	positionMapping[39] = { x: 7, y: 11, position: 39, color: "none" };
	positionMapping[40] = { x: 6, y: 11, position: 40, color: "none" };

	// Goal fields
	// Blue
	positionMapping[41] = { x: 6, y: 10, position: -1, color: "mark-blue" };
	positionMapping[42] = { x: 6, y: 9, position: -2, color: "mark-blue" };
	positionMapping[43] = { x: 6, y: 8, position: -3, color: "mark-blue" };
	positionMapping[44] = { x: 6, y: 7, position: -4, color: "mark-blue" };

	// Yellow
	positionMapping[45] = { x: 2, y: 6, position: -1, color: "mark-yellow" };
	positionMapping[46] = { x: 3, y: 6, position: -2, color: "mark-yellow" };
	positionMapping[47] = { x: 4, y: 6, position: -3, color: "mark-yellow" };
	positionMapping[48] = { x: 5, y: 6, position: -4, color: "mark-yellow" };

	// Green
	positionMapping[49] = { x: 6, y: 2, position: -1, color: "mark-green" };
	positionMapping[50] = { x: 6, y: 3, position: -2, color: "mark-green" };
	positionMapping[51] = { x: 6, y: 4, position: -3, color: "mark-green" };
	positionMapping[52] = { x: 6, y: 5, position: -4, color: "mark-green" };

	// Red
	positionMapping[53] = { x: 10, y: 6, position: -1, color: "mark-red" };
	positionMapping[54] = { x: 9, y: 6, position: -2, color: "mark-red" };
	positionMapping[55] = { x: 8, y: 6, position: -3, color: "mark-red" };
	positionMapping[56] = { x: 7, y: 6, position: -4, color: "mark-red" };

	// Base fields
	// Blue
	positionMapping[57] = { x: 1, y: 11, position: 0, color: "mark-blue" };
	positionMapping[58] = { x: 2, y: 11, position: 0, color: "mark-blue" };
	positionMapping[59] = { x: 1, y: 10, position: 0, color: "mark-blue" };
	positionMapping[60] = { x: 2, y: 10, position: 0, color: "mark-blue" };

	// Yellow
	positionMapping[61] = { x: 1, y: 1, position: 0, color: "mark-yellow" };
	positionMapping[62] = { x: 1, y: 2, position: 0, color: "mark-yellow" };
	positionMapping[63] = { x: 2, y: 1, position: 0, color: "mark-yellow" };
	positionMapping[64] = { x: 2, y: 2, position: 0, color: "mark-yellow" };

	// Green
	positionMapping[65] = { x: 11, y: 1, position: 0, color: "mark-green" };
	positionMapping[66] = { x: 10, y: 1, position: 0, color: "mark-green" };
	positionMapping[67] = { x: 11, y: 2, position: 0, color: "mark-green" };
	positionMapping[68] = { x: 10, y: 2, position: 0, color: "mark-green" };

	// Red
	positionMapping[69] = { x: 11, y: 11, position: 0, color: "mark-red" };
	positionMapping[70] = { x: 10, y: 11, position: 0, color: "mark-red" };
	positionMapping[71] = { x: 11, y: 10, position: 0, color: "mark-red" };
	positionMapping[72] = { x: 10, y: 10, position: 0, color: "mark-red" };


	figures.forEach((figure) => {
		// Goal fields
		if (figure.position < 0) {
			positionMapping[40 + -figure.position + colors[figure.color] * 4].color = figure.color;
		}
		// Base fields
		else if (figure.position === 0) {
			positionMapping[57 + colors[figure.color] * 4].color = figure.color;
		}
		// Normal fields
		else {
			positionMapping[figure.position].color = figure.color;
		}
	});

	return positionMapping;
}