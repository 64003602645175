import { useEffect, useState } from "react";
import Field, { getFieldLayout } from "../components/Field";
import { FieldPositionType } from "../types";

function Game() {
	const [fields, setFields] = useState<FieldPositionType>([]);

	useEffect(() => {
		getFigures();
	}, []);

	const getFigures = () => {
		// TODO: Get figures from ws

		// Example
		setFields(
			getFieldLayout([
				{ position: 0, color: "blue" },
				{ position: -1, color: "yellow" },
				{ position: 21, color: "green" },
				{ position: 35, color: "red" }
			])
		);
	};

	if (!fields || fields.length === 0) return <div>Loading...</div>;

	return (
		<main className=" m-auto grid gap-4 grid-cols-11 aspect-square max-h-[calc(100vh-6rem)]">
			{fields.map((figure, index) => {
				return <Field {...figure} key={index} />;
			})}
		</main>
	);
}

export default Game;