
function ErrorPage() {
	return (
		<main className=" flex flex-col justify-center align-middle min-h-[calc(100vh-6rem)]">
			<h1 className="text-4xl font-bold text-center">404</h1>
			<h2 className="text-2xl font-bold text-center">Page not found</h2>
		</main>
	);
}

export default ErrorPage;