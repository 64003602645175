import { CSSProperties, MouseEventHandler } from "react";

interface StylingProps {
	width: string,
	height: string,
	className?: string,
	onClick?: MouseEventHandler<SVGSVGElement>,
	style?: CSSProperties,
}

export const Menu = (props: StylingProps) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" onClick={props.onClick} style={props.style} className={props.className} height={props.height} width={props.width} viewBox="0, 0, 48, 48">
			<path className=" fill-black dark:fill-white" d="M4 12h40v4H4v-4Zm0 12h40v4H4v-4Zm0 12h40v4H4v-4Z" />
		</svg>
	);
};
