import { useState } from 'react';
import OutsideAlert from "../lib/OutsideAlert";
import { Menu } from "../lib/svgs";
import Seperator from "./Seperator";
import { Link } from "react-router-dom";

export default function Navbar() {
	const [showMenu, setShowMenu] = useState(false);

	return (
		<OutsideAlert outsideAlertCallback={() => setShowMenu(false)}>
			<nav className=" p-4 z-50 flex w-full fixed top-0 items-center flex-wrap bg-neutral-800">
				<a href="/" className="inline-flex items-center p-2 r-3">
					<span className=" ml-1 text-xl dark:text-white font-bold uppercase tracking-wide">
						Mensch Ärgere Dich Nicht
					</span>
				</a>

				<div className=" ml-auto absolute top-3 right-2 flex flex-row items-center">
					<OutsideAlert outsideAlertCallback={() => { setShowMenu(false); }} className=" ml-3" >
						<>
							<button className=" p-3 mr-0 ml-auto outline-none" onClick={() => setShowMenu(!showMenu)}>
								<Menu width={"2em"} height={"2em"} />
							</button>
							{showMenu &&
								<div className=" flex flex-col p-4 absolute right-2 top-16 bg-neutral-900 rounded-lg shadow-lg">


									<Link to={"/"} className="md:inline-flex md:w-auto w-full px-3 py-2 items-center">
										Home
									</Link>

									<Link to={"/game"} className="md:inline-flex md:w-auto w-full px-3 py-2 items-center">
										Game
									</Link>

									<Seperator />

									<Link to={"/settings"} className="md:inline-flex md:w-auto w-full px-3 py-2 items-center">
										Settings
									</Link>
								</div>
							}
						</>
					</OutsideAlert>
				</div>

			</nav >
		</OutsideAlert >
	);
}